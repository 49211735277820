<!-- 提现申请 -->
<template>
  <div>
    <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'提现申请':'提现申请'"
      @update:visible="updateVisible">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px">

        <el-form-item label="可提现金额">
          <!--<el-button @click="Tocomplaint">请选择客户</el-button>-->
          <span>{{firm_wallet.operation_money}}</span>
        </el-form-item>

        <el-form-item label="提现方式">
          支付宝
        </el-form-item>

        <el-form-item label="收款人姓名" prop="real_name">
          <el-input
            placeholder="请输入真实收款人姓名"
            v-model="form.real_name"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="收款账户" prop="account">
          <el-input
            placeholder="请输入真实收款人手机号"
            v-model="form.account"
            clearable>
          </el-input>
        </el-form-item>

        <el-form-item label="提现金额" prop="extract_price">
          <div style="display: flex;align-items: center;">
            <el-input
              style="width: 80%;"
              placeholder="请输入提现金额"
              v-model="form.extract_price"
              clearable>
            </el-input>
            <span style="margin-left: 10px;cursor: pointer;" @click="quanbu">全部提现</span>
          </div>
        </el-form-item>

        <!--0 否 1是-->
        <el-form-item label="是否开票">
          <div>
            <el-switch v-model="form.is_invoice" active-value="1"  inactive-value="0"></el-switch>
          </div>
        </el-form-item>
        <el-form-item>
          <el-upload
            v-if="form.is_invoice == '1'"
            class="upload-demo"
            :limit="1"
            :action="action"
            :headers="headers"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="successUpload"
            :before-remove="beforeRemove"
            multiple
            :on-exceed="handleExceed"
          >
            <div class="shangchuan" style="margin-left: 20px">
              <el-button class="custom-button">
                <i class="el-icon-shangchuan" />
                <span style="vertical-align: middle">点击上传</span>
              </el-button>
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item label="交易密码" prop="password">
          <div style="display: flex;align-items: center;">
            <span v-if="is_pass == false" style="color: #1e60cc;cursor: pointer;" @click="Go">设置支付密码</span>
            <el-input
              v-else
              style="width: 240px!important;"
              clearable
              show-password
              v-model="form.password"
              placeholder="请输入交易密码"/>
            <!--<span style="font-size: 14px;font-weight: 400;color: #7E7F8C;margin-left: 20px;cursor: pointer;">忘记密码？</span>-->
          </div>
        </el-form-item>

      </el-form>


      <div slot="footer">
        <el-button
          @click="updateVisible(false)">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save('form')">确定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      center
      title="设置支付密码"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <el-form
        ref="form1"
        :model="form1"
        :rules="rules1"
        label-width="100px">
        <el-form-item label="交易密码" prop="password">
          <div style="display: flex;align-items: center;">
            <el-input
              style="width: 240px!important;"
              clearable
              show-password
              v-model="form1.password"
              placeholder="请输入交易密码"/>
          </div>
        </el-form-item>
        <el-form-item label="确认密码" prop="twice_password">
          <div style="display: flex;align-items: center;">
            <el-input
              style="width: 240px!important;"
              clearable
              show-password
              v-model="form1.twice_password"
              placeholder="确认密码"/>
          </div>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input
            placeholder="请输入手机号"
            v-model="form1.phone"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="captcha">
          <el-form-item prop="code" label-width="0">
            <el-input placeholder="请输入验证码" v-model="form1.captcha">
              <template slot="append">
                <span style="color: #FEA837;cursor: pointer;" @click="getCode('form1')">
                  {{!codeTime?'获取验证码':codeTime+'s'+'后重新获取'}}
                </span>
              </template>
            </el-input>
          </el-form-item>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button
          @click="quxiao()">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="queding('form1')">确定
        </el-button>
      </div>
    </el-dialog>


  </div>


</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'

// 引入的接口
import {firm_wallet} from "@/api/corporateWallet";
import {is_pay_password, set_pay_password, withdraw} from "@/api/finance";
import {
  getplatform_verify_code,
  getplatform_verify,
} from '@/api/account'

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 上传接口
      action: setting.apiBaseURL + 'common/upload/finance',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },
      // 上传假数据展示
      fileList: [],
      // 表单数据
      form: {
        extract_price:''
      },
      // 验证规则
      rules: {
        real_name: [
          { required: true, message: '请输入真实收款人姓名', trigger: 'blur' },
          { min: 0, max: 10, message: '长度最多输入10个字符', trigger: 'blur' }
        ],
        account:[
          { required: true, message: '请输入真实收款人手机号', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        extract_price: [
          { required: true, message: '请输入提现的金额', trigger: 'blur' },
          { min: 0, max: 20, message: '长度最多输入20个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入交易密码', trigger: 'blur' },
          { min: 0, max: 10, message: '长度最多输入10个字符', trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 0, max: 10, message: '长度最多输入10个字符', trigger: 'blur' }
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //选择服务商
      complaint:false,

      // 表格数据
      DictList: [],
      // 总条目数
      Dicttotal: 0,
      //加载动画
      Dictloading:true,
      current:null,

      //客户列表
      client:[],

      firm_wallet:{},

      // 是否设置了支付密码
      is_pass:true,

      //支付密码弹窗
      dialogVisible:false,
      // 表单数据
      form1: {},
      // 验证规则
      rules1: {
        password: [
          { required: true, message: '请输入交易密码', trigger: 'blur' },
          { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' }
        ],
        twice_password: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' }
        ],
        phone:[
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
      },

      codeText: '获取验证码',
      codeTime: 0,

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {
    this.getpassword();
    this.getfirm_wallet();
  },
  methods: {
    //检测下是否设置了交易密码
    getpassword(){
      is_pay_password().then(res => {
        if(res.code == 200){
          this.is_pass = res.data.is_pass;
          // 判断下是否设置了支付密码 没有的话去设置
        }else {
          this.$message.error(res.msg)
        }
      })
    },

    // 获取验证码
    getCode(){
      this.$refs['form1'].validate((valid) => {
        if (valid) {
          // 获取平台验证码key接口
          getplatform_verify_code().then(res => {
            let data = {
              key: res.data.key,
              phone: this.form1.phone
            }
            // 获取验证码接口
            getplatform_verify(data).then(res => {
              if(res.code === 200){
                //弹出提示框
                if(this.codeText === '获取验证码' && this.codeTime === 0){
                  this.$message({
                    message: '验证码已发送',
                    type: 'success'
                  });
                }
                if (this.codeTime > 0) {
                  this.$message({
                    message: '不能重复获取',
                    type: 'warning'
                  });
                  return;
                } else {
                  this.codeTime = 30
                  let timer = setInterval(() => {
                    this.codeTime--;
                    if (this.codeTime < 1) {
                      clearInterval(timer);
                      this.codeTime = 0
                    }
                  }, 1000)
                }
              }else {
                this.$message.error(res.msg);
              }
            }).catch(err => {
              console.log(err)
            })

          })
        } else {
          return false;
        }
      });
    },

    //获取企业钱包基本信息接口
    getfirm_wallet(){
      firm_wallet().then(res => {
        this.firm_wallet = res.data;
      })
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            extract_price: this.form.extract_price,
            real_name: this.form.real_name,
            account: this.form.account,
            is_invoice: this.form.is_invoice,
            invoice_url: this.form.invoice_url,
            password: this.form.password,
          }
          withdraw(data).then(res => {
            if(res.code == 200){
              this.$message.success(res.msg);
              this.$emit('done');
              this.updateVisible(false);
            }else {
              this.$message.error(res.msg);
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //上传事件
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      // this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      this.$message.warning('只能上传一张')
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file)
      // 上传成功后的列表
      this.form.invoice_url = file.data.urls;
    },

    //点击设置支付密码
    Go(){
      this.dialogVisible = true;
    },
    //关闭设置支付密码
    handleClose(){
      this.dialogVisible = false;
    },
    //点击取消
    quxiao(){
      this.form1 = {};
      this.dialogVisible = false;
    },
    // 点击确定
    queding(){
      this.$refs['form1'].validate((valid) => {
        if (valid) {
          let data = {
            password: this.form1.password,
            twice_password: this.form1.twice_password,
            phone: this.form1.phone,
            captcha: this.form1.captcha,
          }
          set_pay_password(data).then(res => {
            if(res.code == 200){
              this.$message.success(res.msg);
              this.form1 = {};
              this.dialogVisible = false;
              this.getpassword();
            }else {
              this.$message.error(res.msg);
            }
          })
        } else {
          return false;
        }
      });
    },

    //点击全部提现
    quanbu(){
      this.form.extract_price = this.firm_wallet.operation_money;
    },


  }
}
</script>

<style scoped lang="scss">
.no-multiple {
  // 隐藏多选
  ::v-deep .el-table__header-wrapper .el-checkbox {
    display: none;
  }
}
</style>
